<style type="text/css">
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
</style>
<template>
  <div class="about">
    <h1>This is an about-demo page</h1>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: 'about',
  components: {
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../assets/image/pagebgsm.png')
    }
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../assets/image/pagebgbig.png')
    }
  },
  mounted () {},
  methods: {
  }
}
</script>
